import { FC } from 'react';
import { useSelector } from 'react-redux';
import block from 'utils/bem-css-module';

import { DefaultPoint } from 'components/DefaultPoint';
import { DocumentsBlock } from 'components/Documents/documents-block';
import { Heading } from 'components/Heading';
import { Instruction } from 'components/Instruction';
import { MapColumnPageContainer } from 'components/MapColumnPageContainer';
import { useCurrentPoint } from 'hooks/use-current-point';
import { useScreenSize } from 'hooks/use-screen-size';
import { useScrollToTop } from 'hooks/use-scroll-to-top';

import { Show } from 'types/map';
import { State } from 'types/state';

import style from './first-page.scss';

const b = block(style);


const MAX_SCREEN_SIZE_WITH_MAP = 1044;
const MAX_SCREEN_SIZE_WITH_ROW_INSTRUCTION = 616;

export const FirstPage: FC = () => {
	useScrollToTop();

	const user = useSelector(({ user }: State) => user);

	const pointPk = user?.default_point_option || window.defaultPointOption;
	const point = useCurrentPoint(pointPk);
	const { width } = useScreenSize();
	const orientation = width < MAX_SCREEN_SIZE_WITH_ROW_INSTRUCTION ? 'column' : 'row';

	return (
		<MapColumnPageContainer
			className={b('container', { point: Boolean(pointPk) })}
			show={Show.all}
		>
			<Heading size='xl' level={1} className={b('heading')}>Печать документов</Heading>
			<Heading size='xl' level={2} className={b('next-to-home')}>рядом с домом</Heading>
			<DefaultPoint
				withPrice={true}
				point={point}
				linkUrl={width < MAX_SCREEN_SIZE_WITH_MAP ? `/printers?show=${Show.all}&from=${window.location.pathname}` : undefined}
			/>
			<Instruction
				className={b('instruction')}
				orientation={orientation}
				withPlaceSelection={!pointPk}
			/>
			<DocumentsBlock
				className={b('documents')}
				documentsPerPage={9}
			/>
		</MapColumnPageContainer>
	);
};
